<template>
    <div class="activity-schedule">
        <div class="title-wrapper">
            <div class="return_page">
                <VigikBreadCrumb />
            </div>
            <div class="top-block">
                <h1 class="title">
                    {{ $route.params.activityName }}
                </h1>
                <BasicChip
                    :text="
                        $route.params.activityAllow === '1'
                            ? $t('vigik.global.activitiesAllows')
                            : $t('vigik.global.activitiesBanned')
                    "
                    :background="
                        $route.params.activityAllow === '1'
                            ? 'blue-powder'
                            : 'dark-blue'
                    "
                />
            </div>
        </div>
        <week-calendar
            v-if="display"
            :isPersonnalised="isPersonnalised"
            :readonly="false"
            :days="days"
            :maxTimeRange="10"
            :maxDifferentDay="7"
            :initialValue="initialValue"
            :isDeepEqual="isDeepEqual"
            :scheduleId="scheduleId"
            :isReset="isReset"
            @change="updateValue"
            @update:initialDisplayArray="updateInitialDisplayArray"
            @razVerif="razVerif"
            @saveVerif="saveVerif"
            @cancelVerif="cancelVerif"
        />
        <div class="center" v-else>
            <Loader />
        </div>
    </div>
</template>

<script>
import WeekCalendar from "@/components/basic/WeekCalendar"
import VigikBreadCrumb from "@/components/entities/VigikBreadCrumb.vue"
import {
    getActivitySchedule,
    saveActivitySchedule,
    resetActivitySchedule,
} from "@/services/intratone/accesstype"
import Loader from "@/components/basic/Loader"
import BasicChip from "@/components/basic/BasicChip.vue"

import { useToast } from "vue-toastification"
import { deepEqual } from "@/helpers/commonFunctions"
const toast = useToast()

export default {
    name: "ActivitySchedule",
    components: {
        Loader,
        WeekCalendar,
        VigikBreadCrumb,
        BasicChip,
    },
    data() {
        return {
            days: [
                { value: 0, name: "weekType.weekday.monday" },
                { value: 1, name: "weekType.weekday.tuesday" },
                { value: 2, name: "weekType.weekday.wednesday" },
                { value: 3, name: "weekType.weekday.thursday" },
                { value: 4, name: "weekType.weekday.friday" },
                { value: 5, name: "weekType.weekday.saturday" },
                { value: 6, name: "weekType.weekday.sunday" },
            ],
            initialValue: [],
            display: false,
            displayArray: [],
            isPersonnalised: this.$route.params.isPersonnalised,
            scheduleId: this.$route.params.scheduleId,
            initialDisplayArray: [],
            resetRequestInProgress: false,
            isReset: false,
            isExiting: false,
            getAroundBeforeRouteLeave: false,
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        /**
         * user have make no change
         * @proposition: rename this function in "scheduleHasBeenUpdated"
         * @returns {false|this is string[]|boolean}
         */
        isDeepEqual() {
            return deepEqual(this.displayArray, this.initialDisplayArray)
        },
    },
    methods: {
        updateInitialDisplayArray(payload) {
            this.initialDisplayArray = payload.periods
        },
        getData() {
            this.display = false
            getActivitySchedule(
                this.$route.params.accessId,
                this.$route.params.activityId
            ).then((response) => {
                this.initialValue = response.data.days
                this.display = true
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        updateValue(payload) {
            this.displayArray = payload.periods
            // if schedule has been changed
            if (!this.isDeepEqual) {
                this.isReset = false
            } else {
                // if there are reset request in progress
                if (this.resetRequestInProgress) this.isReset = true
            }
        },
        async saveVerif(value) {
            if (!this.isReset) {
                await this.save()
            } else {
                await resetActivitySchedule(
                    this.$route.params.accessId,
                    this.$route.params.activityId
                )
            }

            toast.success(
                this.$t("vigik.schedule.update-schedule", {
                    name: this.$route.params.activityName,
                }),
                {
                    icon: "fas fa-check",
                }
            )
            this.isExiting = value
            this.goBack()
        },
        cancelVerif() {
            this.getData()
        },
        async save() {
            await saveActivitySchedule(
                this.$route.params.accessId,
                this.$route.params.activityId,
                {
                    mode: "full",
                    days: this.displayArray,
                }
            )
        },
        async razVerif() {
            this.display = false
            await resetActivitySchedule(
                this.$route.params.accessId,
                this.$route.params.activityId
            )
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.$router.go(-1)
                    this.display = true
                })
        },
        deepEqual(x, y) {
            const ok = Object.keys,
                tx = typeof x,
                ty = typeof y
            return x && y && tx === "object" && tx === ty
                ? ok(x).length === ok(y).length &&
                      ok(x).every((key) => this.deepEqual(x[key], y[key]))
                : x === y
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isExiting || this.isDeepEqual) {
            next()
        } else {
            this.openModal("validation", {
                title: "valid.edit",
                valid: () => {
                    this.saveVerif()
                    next()
                },
                refuse: () => {
                    next(false)
                },
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.btn-cancel {
    @media all and (max-width: 768px) {
        display: none;
    }
}

.btn-save {
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        top: 85%;
        left: 85%;
        background-color: $orange-neutral;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        font-size: $big;
        color: $white;
        cursor: pointer;
        z-index: 10;
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}

.btn-raz {
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        top: 78%;
        left: 85%;
        background-color: $blue-neutral;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        font-size: $big;
        color: $white;
        cursor: pointer;
        z-index: 10;
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}
@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.activity-schedule {
    width: 100vw;
    height: 100%;
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: start;
    overflow: auto;
    @media all and (max-width: 768px) {
        padding: 10px 20px;
    }
    .center {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .return_page {
            a {
                color: $blue-darker;
                cursor: pointer;
                text-decoration: none;
                transition: color 200ms;
                font-family: "Avenir Heavy";
                font-size: 12px;
                i {
                    margin-right: 10px;
                    transition: margin 200ms;
                }
            }
            a:hover {
                color: $orange-neutral;
                i {
                    margin-right: 15px;
                }
            }
        }
        .top-block {
            display: flex;
            gap: 1em;
            align-items: center;
            width: max-content;
            @media all and (max-width: 768px) {
                flex-direction: column;
                align-items: start;
            }
            .title {
                font-family: $font_avenir_black;
                margin: 0;
                @media all and (min-width: 768px) {
                    font-size: $veryBig;
                }
                @media all and (max-width: 768px) {
                    font-size: $veryBig;
                    flex-direction: column;
                }
            }
        }
    }
}
</style>
